import { qom } from './qom'
// import { bsc } from './bsc'
import { tqom } from './tqom'
// import { dogechain } from './dogechain'
// import { dokenchain } from './dokenchain'
// import { fuse } from './fuse'
// import { xdc } from './xdc'

export const chainMap = { qom,  tqom  }
export const chains = Object.values(chainMap)

export const getChain = (chainId: number) => {
  return chains.find((chain) => chain.id === chainId)
}

export enum ChainId {
  QOM = qom.id,
  // BSC = bsc.id,
  TQOM = tqom.id,
  // DOGE = dogechain.id,
  // DOKEN = dokenchain.id,
  // FUSE = fuse.id,
  // XDC = xdc.id,
}
