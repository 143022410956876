import { ChainId, ERC20Token, WETH9 } from '@pancakeswap/sdk'

export const qomTokens = {
  wqom: WETH9[ChainId.QOM],
  score: new ERC20Token(ChainId.QOM, '0xA20b3B97df3a02f9185175760300a06B4e0A2C05', 18, 'SCORE', 'StakedCore', ''),
  qswap: new ERC20Token(ChainId.QOM, '0x4AF41F453Ead00d1cc6Aa0b5B454bdeB102AFc8d', 18, 'QSWAP', 'QOM SWAP', ''),
  // testToken: new ERC20Token(ChainId.QOM, '0x486D0c074834c85e33312C624cB056E0c8bf85a4', 18, 'TestToken', 'TestToken', ''),
  ice: new ERC20Token(ChainId.QOM, '0x0360866Bf85d4c2AefaC3577BC06398867EBfB5d', 18, 'TestToken', 'TestToken', ''),
  usdt: new ERC20Token(
    ChainId.QOM,
    '0xC276b91c0e8D10260af7b67009d2683bB7776308',
    6,
    'qUSDT',
    'Tether USD FROM ETHEREUM',
    '',
  ),
  usdc: new ERC20Token(ChainId.QOM, '0xb0a125E9673DC8e12BbCD0769c1257Cf4A836D54', 6, 'qUSDC', 'USDC FROM ETHEREUM', ''),
}
