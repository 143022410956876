import { FACTORY_ADDRESS, INIT_CODE_HASH } from '../common/swap'
import IceChain from '../ice-chain'

export const tqom: IceChain = {
  id: 7668378,
  name: 'QomTestnet Blockchain',
  features: ['swap', 'bridge', 'farms', 'info', 'locks', 'staking'],
  network: 'tQOM',
  rpcUrls: {
    public: 'https://rpc.testnet.qom.one',
    default: 'https://rpc.testnet.qom.one',
  },
  blockExplorers: {
    default: { name: 'tQOM Explorer', url: 'https://testnet.qom.one' },
  },
  nativeCurrency: {
    name: 'tQOM',
    symbol: 'tQOM',
    decimals: 18,
  },
  multicall: {
    address: '0x741422E11CDD6aDfd29cbef525689F19Fc49a776',
    blockCreated: 1346115,
  },
  locks: {
    factoryAddress: '0xA48E76d95619f4c9838Df19FDeE690a06581b5dD',
  },
  tokenDeployer: {
    address: '0x3d14cB33D5A4ce59625C32291719691953061903',
  },
  blockInterval: 3,
  wrappedNative: {
    address: '0x74a84ACFFb40c78c4B471d6C531B3532f542984b',
    decimals: 18,
    symbol: 'WQOM',
    name: 'Wrapped QOM',
  },
  swap: {
    factoryAddress: FACTORY_ADDRESS,
    initCodeHash: INIT_CODE_HASH,
  },
  campaignFactory: '0x79218D6d562a435ec258f2f4D8D17f1DEbbb114a',
}
