import { formatEther } from '@ethersproject/units'
import { MultiCallV2 } from '@pancakeswap/multicall'
import { masterChefAddresses } from './const'
import { farmV2FetchFarms, FetchFarmsParams, fetchMasterChefV2Data } from './fetchFarms'
import { chains } from '@icecreamswap/constants'

export const SUPPORT_FARMS = chains.filter((chain) => chain.features.includes('farms')).map((chain) => chain.id)
export const bCakeSupportedChainId = []
export { masterChefAddresses } from './const'

export function createFarmFetcher(multicallv2: MultiCallV2) {
  const fetchFarms = async (params: Pick<FetchFarmsParams, 'chainId' | 'farms'>) => {
    // console.log('fetchFarms called with params:', params) // Log the input parameters

    const { farms, chainId } = params
    const masterChefAddress = masterChefAddresses[766]
    // console.log('masterChefAddress:', masterChefAddress) // Log the MasterChef address

    // Fetch data from MasterChef
    const { poolLength, totalRegularAllocPoint, totalSpecialAllocPoint, qswapPerBlock } = await fetchMasterChefV2Data({
      multicallv2,
      masterChefAddress,
      chainId,
    })

    // console.log('Fetched MasterChef Data:', {
    //   poolLength,
    //   totalRegularAllocPoint,
    //   totalSpecialAllocPoint,
    //   qswapPerBlock,
    // }) // Log the fetched data from MasterChef

    const regularCakePerBlock = formatEther(qswapPerBlock)
    // console.log('regularCakePerBlock:', regularCakePerBlock) // Log the formatted regularCakePerBlock

    // Filter farms and fetch data with prices
    const farmsWithPrice = await farmV2FetchFarms({
      multicallv2,
      masterChefAddress,
      chainId,
      farms: farms.filter((f) => !f.pid || poolLength.gt(f.pid)),
      totalRegularAllocPoint,
      totalSpecialAllocPoint,
    })

    // console.log('Farms with price:', farmsWithPrice) // Log the farms with prices

    return {
      farmsWithPrice,
      poolLength: poolLength.toNumber(),
      regularCakePerBlock: +regularCakePerBlock,
    }
  }

  return {
    fetchFarms,
    isChainSupported: (chainId: number) => SUPPORT_FARMS.includes(chainId),
    SUPPORT_FARMS,
  }
}

export * from './apr'
export * from './farmsPriceHelpers'
export * from './types'
export { getFarmsPriceHelperLpFiles } from '../constants/priceHelperLps/getFarmsPriceHelperLpFiles'
export { getFarmConfig, getStableConfig } from '../constants'
