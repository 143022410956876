import { SerializedFarmConfig } from '@pancakeswap/farms'
import { coreTokens } from '@pancakeswap/tokens'

const priceHelperLps: SerializedFarmConfig[] = [
    // {
    //     pid: 0,
    //     lpSymbol: 'USDT-WCORE LP',
    //     lpAddress: '0x9339BaEF96E619aBf452CE043A3b45EE1c805268',
    //     token: coreTokens.usdt,
    //     quoteToken: coreTokens.wcore,
    // },
].map((p) => ({ ...p, token: p.token.serialize, quoteToken: p.quoteToken.serialize }))

export default priceHelperLps
