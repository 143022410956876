import {ChainId, ERC20Token, WETH9} from '@pancakeswap/sdk'

export const coreTokens = {
  wcore: WETH9[ChainId.TQOM],
  score: new ERC20Token(ChainId.TQOM, '0xA20b3B97df3a02f9185175760300a06B4e0A2C05', 18, 'SCORE', 'StakedCore', ''),
  ice: new ERC20Token(ChainId.TQOM, '0x0360866Bf85d4c2AefaC3577BC06398867EBfB5d', 18, 'TestToken', 'TestToken', ''),
  usdt: new ERC20Token(ChainId.TQOM, '0x533320D9d024ed6e4c7D71e0eB232Eb8aB47eeb1', 18, 'USDT', 'Tether USD'),
  
  
}
