import { ChainId, WETH9, ERC20Token } from '@pancakeswap/sdk'

export const bitgertTokens = {
  wbrise: WETH9[ChainId.QOM],
  ice: new ERC20Token(ChainId.QOM, '0xB999Ea90607a826A3E6E6646B404c3C7d11fa39D', 18, 'TEST', 'IceCream', 'https://icecreamswap.com'),
  sps: new ERC20Token(ChainId.QOM, '0x1633b7157e7638C4d6593436111Bf125Ee74703F', 18, 'SPS', 'Splinterlands Token', 'https://splinterlands.com'),
  sphynx: new ERC20Token(ChainId.QOM, '0x0e11DCE06eF2FeD6f78CEF5144F970E1184b4298', 18, 'SPHYNX', 'Sphynx BRISE'),
  bpad: new ERC20Token(ChainId.QOM, '0x71946a5C9dA7C95ee804a9BE561EC15A3F286A7D', 8, 'BPAD', 'Brisepad'),
  broge: new ERC20Token(ChainId.QOM, '0x41c5ae56681Fb19334eCF7d914919805DaE2Ec8f', 18, 'BROGE', 'BROGE'),
  brzilla: new ERC20Token(ChainId.QOM, '0x9b8535Dd9281e48484725bC9Eb6Ed2f66CEA2a36', 18, 'BRZILLA', 'BriseZilla'),
  btxt: new ERC20Token(ChainId.QOM, '0x1A8a039007186d7640C1D7Cd7c2606e333D04e03', 18, 'BTXT', 'BitsXT'),
  eltg: new ERC20Token(ChainId.QOM, '0xb860eCD8400600c13342a751408737235E177077', 9, 'ELTG', 'Graphen'),
  evo: new ERC20Token(ChainId.QOM, '0x267Ae4bA9CE5ef3c87629812596b0D89EcBD81dD', 18, 'EVO', 'EVO'),
  map: new ERC20Token(ChainId.QOM, '0x6D347fdCb302a5879545E01EceE7A176db23dCDa', 2, 'MAP', '4D Twin Maps'),
  miidas: new ERC20Token(ChainId.QOM, '0x5B534A2Df329195Fd7e5c9AcA1D9ffbdA14A4963', 6, 'Miidas', 'Miidas NFT', 'https://brise.miidas.com/'),
  mir: new ERC20Token(ChainId.QOM, '0x2468dad471fA7E03d8029F057cc41742F017D53d', 18, 'MIR', 'Mix Reality'),
  numi: new ERC20Token(ChainId.QOM, '0x6718e47e74497d1564EE76d832309144b83Ef8E8', 18, 'NUMI', 'Numitor'),
  omnia: new ERC20Token(ChainId.QOM, '0x5d4685c2C75581C67b9D6292A065a767bC214681', 8, 'OMNIA', 'OmniaVerse'),
  prds: new ERC20Token(ChainId.QOM, '0x31226B28add9062c5064a9Bd35eA155F323C6ca6', 9, 'PRDS', 'Brise Paradise'),
  rluna: new ERC20Token(ChainId.QOM, '0x6660A7AF57fAE695D4a10D645088aBA9fb547728', 18, 'RLUNA', 'Rise Luna'),
  vef: new ERC20Token(ChainId.QOM, '0xD6447d2fA919811c41a064bDbdaB1E281F8de9B2', 18, 'VEF', 'Vefi Ecosystem Token'),
  wmf: new ERC20Token(ChainId.QOM, '0xc89fcd3E1CF5A355fc41E160d18BAC5f624610D4', 18, 'WMF', 'Whale Maker Fund'),
  yogo: new ERC20Token(ChainId.QOM, '0xB361D5953e21Cfde5CD62B89FDf40bc21903A6bb', 18, 'YOGO', 'YOGO Token'),
  ypc: new ERC20Token(ChainId.QOM, '0x11203a00a9134Db8586381C4B2fca0816476b3FD', 18, 'YPC', 'Young Parrot'),
  tokyo: new ERC20Token(ChainId.QOM, '0x38EA4741d100cAe9700f66B194777F31919142Ee', 9, '$Tokyo', 'Metaverse City Tokyo'),
  usdc: new ERC20Token(ChainId.QOM, '0x765277EebeCA2e31912C9946eAe1021199B39C61', 6, 'USDCm', 'USD Coin Multichain'),
  usdt: new ERC20Token(ChainId.QOM, '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D', 6, 'USDTm', 'Tether USD Multichain'),
  wolf: new ERC20Token(ChainId.QOM, '0x4Fb3DBF9111169ff60fFB8a7be1c6Fd3D4E417bC', 19, 'WOLF', 'Alpha Trades'),
  abr: new ERC20Token(ChainId.QOM, '0x9F7Bb6E8386ac9ad5e944d66fBa80F3F7231FA94', 9, 'ABR', 'AIBRA'),
  baskom: new ERC20Token(ChainId.QOM, '0x6cd08bE8Aa9B705Ca86B4923B1784C0eE06E5220', 9, 'BASKOM', 'Brisecom'),
  lung: new ERC20Token(ChainId.QOM, '0xc3b730dD10A7e9A69204bDf6cb5A426e4f1F09E3', 18, 'LUNG', 'LunaGens'),
  usdti: new ERC20Token(ChainId.QOM, '0xC7E6d7E08A89209F02af47965337714153c529F0', 18, 'USDTi', 'Tether USD IcecreamSwap'),
  $3dc: new ERC20Token(ChainId.QOM, '0x5feDA75eaB27814Cba0694C9711F7d4abEa5b0b5', 8, '$3DC', '3D City'),
  darrival: new ERC20Token(ChainId.QOM, '0xeB18A16A08530b811523fA49310319809ac4c979', 9, 'DRV', 'Darrival'),
  arco: new ERC20Token(ChainId.QOM, '0xbfa6394b9898566652276f1Fb40d65e75787D66f', 9, 'ARCO', 'Ardiansyah Crypto'),
  ethi: new ERC20Token(ChainId.QOM, '0xeA61Dc96F105469522d39cBF7bD59b42393678F7', 18, 'ETHi', 'Ether IcecreamSwap'),
  dogei: new ERC20Token(ChainId.QOM, '0x46a8E16dB8Bb241618873bCA21Ef02F120EA4125', 18, 'DOGEi', 'Dogecoin IcecreamSwap'),
  bnbi: new ERC20Token(ChainId.QOM, '0x74446a7418BFbFCDe3F1f6bCaFFA097d050a6dD8', 18, 'BNBi', 'BNB IcecreamSwap'),
  shibi: new ERC20Token(ChainId.QOM, '0xADF3051f6fbC1f42ee20B2eDb47EA7f6CcaBe978', 18, 'SHIBi', 'Shiba Inu IcecreamSwap'),
  daii: new ERC20Token(ChainId.QOM, '0x71Ef0A490E53Cc177F640169b0347Be4d0f23cc9', 18, 'DAIi', 'DAI IcecreamSwap'),
  usdci: new ERC20Token(ChainId.QOM, '0xaEdD3Ff7b9Fc5fc4e44d140b80f0B1C7FdB6102c', 18, 'USDCi', 'USD Coin IcecreamSwap'),
  busdi: new ERC20Token(ChainId.QOM, '0xd0CE781960c6356A7175988751bfC8d7cd28EA60', 18, 'BUSDi', 'BUSD IcecreamSwap'),
}
