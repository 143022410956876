import Airdrop from 'views/Airdrop'
import { FACTORY_ADDRESS, INIT_CODE_HASH } from '../common/swap'
import IceChain from '../ice-chain'

// MAINNET
export const qom: IceChain = {
  id: 766,
  name: 'QOM',
  features: ['swap', 'bridge', 'info', 'farms', 'staking', 'locks'],
  network: 'QOM',
  rpcUrls: {
    public: 'https://rpc.qom.one',
    default: 'https://rpc.qom.one',
  },
  blockExplorers: {
    default: { name: 'Qomscan', url: 'https://scan.qom.one' },
  },
  nativeCurrency: {
    name: 'QOM',
    symbol: 'QOM',
    decimals: 18,
  },
  blockInterval: 5.4,
  multicall: {
    address: '0x905F05Bf383F252Ac1d56Ff6177364970C0032B6',
    blockCreated: 10245,
  },
  locks: {
    factoryAddress: '0x1E7Fe378aD7df9ec7534a3443a2eF1e632dB4dd6',
  },
  airdrop: {
    airdropAddress: '0x3B39c46A91CF628e22ceC4D174EB11F217771EcC',
  },
  auction: {
    auctionAddress: '0x3B39c46A91CF628e22ceC4D174EB11F217771EcC',
  },
  wrappedNative: {
    address: '0xa26dfBF98Dd1A32FAe56A3D2B2D60A8a41b0bDF0',
    decimals: 18,
    symbol: 'WQOM',
    name: 'Wrapped Qom',
  },
  swap: {
    factoryAddress: FACTORY_ADDRESS,
    initCodeHash: INIT_CODE_HASH,
  },
}
