import { SerializedFarmConfig } from '@pancakeswap/farms'
import { coreTokens, qomTokens } from '@pancakeswap/tokens'

const priceHelperLps: SerializedFarmConfig[] = [
  // {
  //   pid: 0,
  //   lpSymbol: 'WQOM-qUSDT LP',
  //   lpAddress: '0xA21d14Fe48f48556f760Fa173aE9Bc3f6a996C5B',
  //   token: qomTokens.usdt,
  //   quoteToken: qomTokens.wqom,
  // },
].map((p) => ({ ...p, token: p.token.serialize, quoteToken: p.quoteToken.serialize }))

export default priceHelperLps
