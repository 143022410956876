import { ChainId } from '@pancakeswap/sdk'

const chainName: Record<ChainId, string> = {
  [ChainId.QOM]: 'QOM',
  // [ChainId.DOGE]: 'Doge',
  // [ChainId.DOKEN]: 'Doken',
  // [ChainId.FUSE]: 'Fuse',
  // [ChainId.XDC]: 'XDC',
  // [ChainId.BSC]: 'Binance',
  [ChainId.TQOM]: 'QOM TESTNET',
}

export default chainName
