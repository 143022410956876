import { ChainId } from '@pancakeswap/sdk'
import FarmsQomPriceHelper from './766'
import FarmsCorePriceHelper from './7668378'

export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.QOM:
      return FarmsQomPriceHelper
    case ChainId.TQOM:
      return FarmsCorePriceHelper
    default:
      return []
  }
}
