import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  DropdownMenuItems,
  InfoIcon,
  BridgeIcon,
  DropdownMenuItemType,
  RocketIcon,
  MoreHorizontalIcon,
  SmartContractIcon,
  TokenPocketIcon,
  TimerIcon,
  LockIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import {
  SUPPORT_FARMS,
  SUPPORT_STAKING,
  SUPPORT_INFO,
  SUPPORT_SWAP,
  SUPPORT_BRIDGE,
  SUPPORT_LOCKS,
} from '../../../config/constants/supportChains'
import { useWeb3React } from '@pancakeswap/wagmi'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
  account?: string,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId, account) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      supportChainIds: SUPPORT_SWAP,
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        /*
        {
          label: t('Limit'),
          href: '/limit-orders',
          supportChainIds: SUPPORT_ONLY_BSC,
          image: '/images/decorations/3d-coin.png',
        },
           */
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
        /*
        {
          label: t('Perpetual'),
          href: `https://perp.icecreamswap.com/${perpLangMap(languageCode)}/futures/BTCUSDT?theme=${perpTheme(
            isDark,
          )}`,
          supportChainIds: SUPPORT_ONLY_BSC,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('Bridge'),
          href: 'https://bridge.icecreamswap.com/',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
           */
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Earn'),
      href: '/farms',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      image: '/images/decorations/pe2.png',
      showItemsOnMobile: false,
      supportChainIds: [...SUPPORT_FARMS, ...SUPPORT_STAKING],
      items: [
        {
          label: t('Liquidity Farms'),
          href: '/farms',
          supportChainIds: SUPPORT_FARMS,
        },
        {
          label: t('Staking'),
          href: '/pools',
          supportChainIds: SUPPORT_STAKING,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },

    {
      label: t('Auctions'),
      href: '/auctions',
      hideSubNav: false,
      icon: TimerIcon,
      showItemsOnMobile: false,

      items: [
        {
          label: t('Auctions'),
          href: '/auctions',
        },
        // {
        //   label: t('Create'),
        //   href: '/pools',
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },

    /*
     
    
    {
      label: t('Win'),
      href: '/prediction',
      icon: TrophyIcon,
      fillIcon: TrophyFillIcon,
      supportChainIds: SUPPORT_ONLY_BSC,
      items: [
        {
          label: t('Trading Competition'),
          href: '/competition',
          image: '/images/decorations/tc.png',
          hideSubNav: true,
        },
        {
          label: t('Prediction (BETA)'),
          href: '/prediction',
          image: '/images/decorations/prediction.png',
        },
        {
          label: t('Lottery'),
          href: '/lottery',
          image: '/images/decorations/lottery.png',
        },
        {
          label: t('Pottery (BETA)'),
          href: '/pottery',
          image: '/images/decorations/lottery.png',
        },
      ],
    },
       */
    /*
    {
      label: t('NFT'),
      href: `${nftsBaseUrl}`,
      icon: NftIcon,
      fillIcon: NftFillIcon,
      supportChainIds: SUPPORT_ONLY_BSC,
      image: '/images/decorations/nft.png',
      items: [
        {
          label: t('Overview'),
          href: `${nftsBaseUrl}`,
        },
        {
          label: t('Collections'),
          href: `${nftsBaseUrl}/collections`,
        },
        {
          label: t('Activity'),
          href: `${nftsBaseUrl}/activity`,
        },
      ],
    },
       */
    /*
    {
      label: t('Info'),
      href: '',
      type: DropdownMenuItemType.EXTERNAL_LINK,
      icon: InfoIcon,
      hideSubNav: true,
      items: [],
    },
    */
    // {
    //   label: t('Launchpad'),
    //   href: '/launchpad',
    //   icon: RocketIcon,
    //   hideSubNav: true,
    //   showItemsOnMobile: false,
    //   items: [],
    // },
    {
      label: t('Tools'),
      href: '/locks',
      icon: LockIcon,
      showItemsOnMobile: true,
      hideSubNav: true,
      supportChainIds: [...SUPPORT_INFO, ...SUPPORT_LOCKS],
      items: [
        {
          label: t('Locker'),
          href: '/locks',
          supportChainIds: SUPPORT_INFO,
        },
        {
          label: t('Portfolio'),
          href: `https://qom-tracker.web.app/?address=${account}`,
          supportChainIds: SUPPORT_INFO,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },

        // {
        //   label: t('Locks'),
        //   href: '/locks',
        //   supportChainIds: SUPPORT_LOCKS,
        // },
        // {
        //   label: t('Wiki'),
        //   href: '',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('Create Token'),
        //   href: '/create-token',
        //   supportChainIds: SUPPORT_LOCKS,
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('More'),
      icon: MoreHorizontalIcon,
      showItemsOnMobile: true,
      hideSubNav: true,
      supportChainIds: [...SUPPORT_INFO, ...SUPPORT_LOCKS],
      items: [
        {
          label: t('Info'),
          href: '/info',
          supportChainIds: SUPPORT_INFO,
        },
        {
          label: t('Portfolio'),
          href: 'https://qom-tracker.web.app/',
          supportChainIds: SUPPORT_INFO,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('Whitelist'),
          href: 'https://github.com/RobotMasterxo/qomswapassets/tree/main',
          hideSubNav: true,
          icon: SmartContractIcon,
          showItemsOnMobile: false,
          items: [],
        },
        {
          label: t('Airdrop'),
          href: '/airdrop',
          hideSubNav: true,
          icon: TimerIcon,
          showItemsOnMobile: false,
          items: [],
        },
        // {
        //   label: t('Locks'),
        //   href: '/locks',
        //   supportChainIds: SUPPORT_LOCKS,
        // },
        // {
        //   label: t('Wiki'),
        //   href: '',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('Create Token'),
        //   href: '/create-token',
        //   supportChainIds: SUPPORT_LOCKS,
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    /*
    {
      label: '',
      href: '/info',
      icon: MoreIcon,
      hideSubNav: true,
      items: [
        {
          label: t('Info'),
          href: '/info',
        },
        {
          label: t('IFO'),
          href: '/ifo',
          supportChainIds: SUPPORT_ONLY_BSC,
          image: '/images/ifos/ifo-bunny.png',
        },
        {
          label: t('Voting'),
          href: '/voting',
          supportChainIds: SUPPORT_ONLY_BSC,
          image: '/images/voting/voting-bunny.png',
        },
        {
          type: DropdownMenuItemType.DIVIDER,
        },
        {
          label: t('Leaderboard'),
          href: '/teams',
          supportChainIds: SUPPORT_ONLY_BSC,
          image: '/images/decorations/leaderboard.png',
        },
        {
          type: DropdownMenuItemType.DIVIDER,
        },
        {
          label: t('Blog'),
          href: 'https://medium.com/pancakeswap',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('Docs'),
          href: '',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
       */
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
