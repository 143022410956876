import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import { bitgertTokens } from './32520'
import { qomTokens } from './766'
// import { dogechainTokens } from './2000'
// import { dokenTokens } from './61916'
// import { fuseTokens } from './122'
// import { xdcTokens } from './50'
import { coreTokens } from './7668378'

export const USD: Record<ChainId, ERC20Token> = {
  [ChainId.QOM]: qomTokens.usdt,
  // [ChainId.DOGE]: dogechainTokens.usdt,
  // [ChainId.DOKEN]: dokenTokens.usdt,
  // [ChainId.FUSE]: fuseTokens.usdt,
  // [ChainId.XDC]: xdcTokens.usdt,
  // [ChainId.BSC]: bitgertTokens.usdti,  // todo: add proper BSC USDT
  [ChainId.TQOM]: coreTokens.usdt,
}

export const QSWAP: Record<ChainId, ERC20Token> = {
  [ChainId.QOM]: qomTokens.qswap,
  // [ChainId.DOGE]: dogechainTokens.ice,
  // [ChainId.DOKEN]: dokenTokens.ice,
  // [ChainId.FUSE]: fuseTokens.ice,
  // [ChainId.XDC]: xdcTokens.ice,
  // [ChainId.BSC]: bitgertTokens.ice,  // todo: add proper BSC ICE
  [ChainId.TQOM]: coreTokens.ice,
}
